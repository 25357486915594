import "./App.css";
import {
  Container,
  Header,
  Image,
  Icon,
  Grid,
} from "semantic-ui-react";
import portrait from "./Components/Images/portrait.jpg";
import laptop from "./Components/Images/laptop.jpg";
import sc from "./Components/Images/SC_icon.png"

const App = () => {
  return (
    <>
      <div className="banner">
        <Container
          fluid
          style={{
            padding: "40px",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid container stackable>
            <Grid.Row columns={2}>
              <Grid.Column
                width={12}
                floated="left"
                textAlign="left"
                justifyContent="center"
              >
                <Header
                  style={{
                    color: "white",
                    fontSize: "56px",
                    marginBottom: "0",
                  }}
                >
                  Kaeti Gust
                </Header>
                <Header as="h1" style={{ color: "white", marginTop: "0" }}>
                  Fullstack Developer
                </Header>
                <br />
                <a href={'https://github.com/KaetiG'}>
                <Icon name="github" link inverted color="grey" size="big" />
                </a>
                <a href={'https://www.linkedin.com/in/kaeti-gust-4996b151/'}>
                <Icon name="linkedin" link inverted color="grey" size="big" />
                </a>
                <Header
                  as="h3"
                  style={{ color: "white", marginTop: "0", marginBottom: "0" }}
                >
                  Phone: (612) 562 - 6865 <br />
                  Email: kaetigust@gmail.com
                </Header>
              </Grid.Column>
              <Grid.Column width={4} floated="right">
                <Image
                  src={portrait}
                  circular
                  floated="right"
                  style={{ height: "auto", width: "100%", alignItems: "right" }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
      <div className="main-content">
        <Container style={{paddingTop: "20px"}}>
          <Grid stackable divided='vertically'>
          <Grid.Row columns={2}>
            <Grid.Column alignItems="center" justifyContent="center" width={6}>
          <Image
            src={laptop}
            rounded
            style={{ height: "auto", width: "100%", alignItems: "center" }}
          />
          </Grid.Column>
          <Grid.Column floated="right" width={10}>
            <Header as="h1">About</Header>
          <h3>
            Hello! I am a Minneapolis based software engineer. <br />
            I work mainly with TypeScript, React/React Native, Node, Express,
            GraphQL, and PostgreSQL.
            Others I've worked with include: BabylonJS, jQuery, Material-UI,
            Semantic-UI, Bootstrap, Mongo, Redux/Redux Sagas, Cypress, Playwright, Docker, HTML, and CSS.
            <br />I have also begun learning UX design. I work with figma, Adobe Illustrator, Adobe Photoshop, and Adobe InDesign. 
            <br />I am currently working at Target as a UI Engineer.
          </h3>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column floated="left" width={12}>
                  <Header as="h1">Current Projects</Header>
                  <h3>I've been on a variety of products with Target's merchandising team mostly focusing on 3D design and development! 
                    I also added many features and improvements to the Target owned mobile app; Studio Connect! 
                    Currently I'm an engineer on a design workflow application used by owned brand designers, as well as doing the UX design for an AI based project within Target. 
                  </h3>
            </Grid.Column>
            <Grid.Column floated="right" width={4} style={{padding: "0"}}>
            <Image
            src={sc}
            floated="right"
            rounded
            style={{ height: "auto", width: "100%", margin: "0" }}
          />
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Container>
      </div>
      <Container
        fluid
        style={{
          position: "relative",
          height: "5%",
          width: "100%",
          bottom: "0px",
          padding: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: "0",
        }}
      >
        <p>Copyright © Katelyn Gust 2024</p>
      </Container>
    </>
  );
};

export default App;
